import {
  ConfirmEmailResp,
  LoginUrlResp,
  OTPConfirmReqBody,
  RegisterAccountReqBody,
  RegisterAccountResp,
  ResendOtpReqBody,
  UserOAuthConnection,
} from '@ytl/common-model';
import axios, { AxiosError } from 'axios';
import { getConfig } from '../data/config';
import { axiosInstance } from './axiosInstance';

const _confirmRegistrationOTP = async (accountId: string, otp: string) => {
  const res = await axiosInstance.post<OTPConfirmReqBody>(
    `/api/register-account/confirm-otp`,
    {
      accountId,
      otp,
    },
    { maxRedirects: 0 },
  );

  return res.headers?.location;
};

export const accountResource = {
  async confirmEmail(token: string) {
    const res = await axiosInstance.post<ConfirmEmailResp>(`/api/confirm-email`, { token });
    return res.data;
  },
  updateAccountName(name: string, accountId: string) {
    return axiosInstance.post(`/api/update-account`, { name, accountId });
  },
  async updateEmail(email: string, accountId: string) {
    try {
      // const withOtp = false;
      return await axiosInstance.post(`/api/update-account`, { email, accountId });
    } catch (err) {
      if ((err as AxiosError).response?.status === 401 && (err as AxiosError).response?.headers.location) {
        window.location.href = (err as AxiosError).response?.headers.location as string;
      }
      throw err;
    }
  },
  async changeEmailYolo(email: string, accountId: string) {
    try {
      const withOtp = true;
      return await axiosInstance.post(`/api/update-account`, { email, accountId, withOtp });
    } catch (err) {
      if ((err as AxiosError).response?.status === 401 && (err as AxiosError).response?.headers.location) {
        window.location.href = (err as AxiosError).response?.headers.location as string;
      }
      throw err;
    }
  },
  async changeEmailYoloConfirm(accountId: string, otp: string) {
    await axiosInstance.post(`/api/update-account/confirm-otp`, { accountId, otp });
  },
  async deleteAccount(accountId: string) {
    const res = await axiosInstance.post(`/api/delete-account`, { accountId });
    if (res.status === 200) {
      const logoutUrl = res.data?.logoutUrl as string;
      const token = res.data?.token as string;
      await this.performSsoLogout(logoutUrl, token);
    }
  },
  disconnectOauthAccount(accountId: string, provider: keyof UserOAuthConnection) {
    return axiosInstance.post(`/api/disconnect-oauth-account`, { accountId, provider });
  },
  async registerAccount(props: RegisterAccountReqBody) {
    const res = await axiosInstance.post<RegisterAccountResp>(`/api/register-account`, props);
    if (res.status === 200) {
      return res.data?.accountId;
    }
  },
  async confirmRegistrationOTP(accountId: string, otp: string) {
    const loc = await _confirmRegistrationOTP(accountId, otp);
    if (loc) {
      window.location.href = loc;
    }
  },
  async confirmRegistrationOTPWebView(accountId: string, otp: string, isYoloRegister: boolean) {
    if (!isYoloRegister) {
      const loc = await _confirmRegistrationOTP(accountId, otp);
      if (loc) {
        window.location.href = loc;
      }
    } else {
      await _confirmRegistrationOTP(accountId, otp);
      const successUrl = new URL(getConfig().closeWebViewUrl);
      successUrl.searchParams.set('success', 'true');
      window.location.href = successUrl.toString();
    }
  },

  async getRegisterLoginUrl() {
    const res = await axiosInstance.get<LoginUrlResp>(`/api/register-account/login-url`, { maxRedirects: 0 });
    if (res.status === 200) {
      return res.data?.url;
    } else {
      return '#';
    }
  },
  async blockEmailChange(sessionId: string) {
    await axiosInstance.get(`/api/block-email-change?sessionId=${sessionId}`);
  },

  async performSsoLogout(logoutUrl: string, token: string) {
    try {
      await axios.create().post(logoutUrl, {}, { headers: { iplanetDirectoryPro: `${token}` } });
    } catch (e) {
      console.error('sso logout error', e);
    }
  },
  fullLogout() {
    return (async () => {
      try {
        const res = await axiosInstance.get(`/api/fullogout`, { maxRedirects: 0 });

        const logoutUrl = res.data?.logoutUrl as string;
        const token = res.data?.token as string;
        await this.performSsoLogout(logoutUrl, token);
      } catch (e) {
        console.error('full logout error', e);
      }
    })();
  },
  async resendOtp(accountId: String) {
    await axiosInstance.post<ResendOtpReqBody>(
      `/api/resend-otp`,
      {
        accountId,
      },
      { maxRedirects: 0 },
    );
  },

  async getYoloRegAuthUrl() {
    const res = await axiosInstance.get(`/api/register-account/login-url`, { maxRedirects: 0 });
    if (res.status === 200) {
      return res.data?.url;
    } else {
      return '#';
    }
  },
};
