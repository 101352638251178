import { Box, BoxProps, Button, Input, Loader, makeLink } from '@ytl/common-web';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Envelope } from '../assets/envelope.svg';
import { ReactComponent as ErrorIcon } from '../assets/error.svg';
import { useLanguage } from '../contexts/languageContext';
import { AppRoutes } from '../routes';
import { getConfig } from '../shared/data/config';
import { PasswordResource } from '../shared/resource/password.resource';
import { isValidEmail } from '../shared/utils/emailValidator';
import { searchOnEnter } from '../shared/utils/searchOnEnter';
import { useLinkParams, useQueryParams } from '../shared/utils/useQueryParams';

enum ForgottenPasswordState {
  NA,
  Success,
  Failed,
}

export const ForgottenPasswordPage = () => {
  const { translation } = useLanguage();
  const queryParams = useQueryParams();
  const [forgottenPasswordState, setForgottenPasswordState] = useState(ForgottenPasswordState.NA);
  const [isLoading, setLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState((queryParams.get('email') || '').trim());
  const [validationErrorList, setValidationErrorList] = useState<Array<string>>([]);
  const linkParams = useLinkParams();

  const resetWithEmail = (email: string) => {
    setForgottenPasswordState(ForgottenPasswordState.NA);
    setEmailAddress(email);
  };

  const tryAgainClicked = useCallback(() => {
    resetWithEmail(emailAddress);
  }, [emailAddress]);

  const query = useQueryParams();
  const navigate = useNavigate();

  const sendResetEmail = useCallback(async () => {
    if (!isValidEmail(emailAddress)) {
      setValidationErrorList([translation.forgottenPassword.error.invalidEmail]);
      return;
    }
    setValidationErrorList([]);

    try {
      setLoading(true);
      await PasswordResource.sendForgottenPasswordEmail(emailAddress);
      setForgottenPasswordState(ForgottenPasswordState.Success);
    } catch (err) {
      setForgottenPasswordState(ForgottenPasswordState.Failed);
    } finally {
      setLoading(false);
    }
  }, [emailAddress, translation]);

  const boxConfig: Omit<BoxProps, 'children'> = useMemo(() => {
    if (forgottenPasswordState === ForgottenPasswordState.NA) {
      return {
        header: translation.forgottenPassword.header(query.has('lock')),
        subHeader: translation.forgottenPassword.subHeader,
        action: (
          <Button
            disabled={isLoading || !emailAddress}
            onClick={sendResetEmail}
          >
            <Loader isLoading={isLoading} />
            {translation.forgottenPassword.send}
          </Button>
        ),
      };
    }
    if (forgottenPasswordState === ForgottenPasswordState.Success) {
      return {
        icon: <Envelope className={'icon'} />,
        header: translation.forgottenPassword.success.header,
        subHeaderHTML: translation.forgottenPassword.success.subHeader(emailAddress),
        action: (
          <Button
            variant={'outlined'}
            onClick={() => {
              const link = makeLink(getConfig().yoloPasswordForgetBacktoLoginUrl, linkParams);

              if (link) {
                window.location.href = link;
              } else {
                navigate(AppRoutes.homepage, { replace: true });
              }
              //
              // if (isApp) {
              //   window.location.href = getConfig().closeWebViewUrl;
              // } else {
              //   navigate(AppRoutes.homepage, { replace: true });
              // }
            }}
          >
            {translation.forgottenPassword.success.backToYettel}
          </Button>
        ),
      };
    }

    return {
      icon: <ErrorIcon className={'icon icon--error'} />,
      headerHTML: translation.forgottenPassword.error.header,
      subHeader: translation.forgottenPassword.error.subHeader,
      action: <Button onClick={tryAgainClicked}>{translation.forgottenPassword.error.tryAgain}</Button>,
    };
  }, [
    emailAddress,
    forgottenPasswordState,
    isLoading,
    sendResetEmail,
    translation,
    tryAgainClicked,
    query,
    navigate,
    linkParams,
  ]);

  return (
    <div className="password-page password-page--forgotten-password">
      <Box {...boxConfig}>
        {forgottenPasswordState === ForgottenPasswordState.NA && (
          <>
            <Input
              label={translation.forgottenPassword.emailLabel}
              name={'email'}
              type={'email'}
              value={emailAddress}
              onChange={e => setEmailAddress(e.target.value)}
              errorList={validationErrorList}
              disabled={isLoading}
              onKeyUp={searchOnEnter(() => sendResetEmail())}
            />
          </>
        )}
        {forgottenPasswordState === ForgottenPasswordState.Success && (
          <>
            <Button
              variant={'text'}
              className="button--no-padding"
              onClick={() => {
                setForgottenPasswordState(ForgottenPasswordState.NA);
                setEmailAddress(emailAddress);
              }}
            >
              {translation.forgottenPassword.success.resend}
            </Button>
          </>
        )}
      </Box>
    </div>
  );
};
