import { MFACreateQrCodeResponse, UserDTO } from '@ytl/common-model';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectCurrentUser } from '../contexts/currentUserSlicer';
import { useLanguage } from '../contexts/languageContext';
import { AppRoutes } from '../routes';
import { mfaResource } from '../shared/resource/mfa.resource';
import { MfaSMSPhoneNumberView } from '../shared/services/mfa/mfaSMSPhoneNumberView';
import { useMfaAuthenticatorQrCodeView } from '../shared/services/mfa/useMfaAuthenticatorQrCodeView';
import { useMfaAuthenticatorSuccessView } from '../shared/services/mfa/useMfaAuthenticatorSuccessView';
import { useMfaEnterAuthenticatorNameView } from '../shared/services/mfa/useMfaEnterAuthenticatorNameView';
import { useMfaSetupView } from '../shared/services/mfa/useMfaSetupView';
import { useMfaSMSSuccessView } from '../shared/services/mfa/useMfaSMSSuccessView';

export enum MFASetupStep {
  SelectType = 'selectType',
  EnterPhoneNumber = 'enterPhoneNumber',
  // EnterSMSCode = 'enterSMSCode',
  EnterAuthenticatorName = 'enterAuthenticatorName',
  ShowAuthenticatorQRCode = 'showAuthenticatorQRCode',
  SuccessSMS = 'successSMS',
  SuccessAuthenticator = 'successAuthenticator',
}

function calcStep(step: MFASetupStep): MFASetupStep {
  const searchParams = new URLSearchParams(window.location.search);

  if (searchParams.get('success') === 'authenticator') {
    return MFASetupStep.SuccessAuthenticator;
  } else if (searchParams.get('success') === 'sms') {
    return MFASetupStep.SuccessSMS;
  } else {
    return step;
  }
}

export const MfaSetupPage = () => {
  const location = useLocation();
  const isB2BMode = location.state?.isB2BMode || false;
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser) as UserDTO;
  const { translation } = useLanguage(); // translation hook használata
  const [qrCode, setQrCode] = useState<MFACreateQrCodeResponse>();

  const [step, setStep] = useState<MFASetupStep>(isB2BMode ? MFASetupStep.EnterPhoneNumber : MFASetupStep.SelectType);

  const { view: selectTypeView } = useMfaSetupView((mode: 'sms' | 'authenticator') => {
    setStep(mode === 'sms' ? MFASetupStep.EnterPhoneNumber : MFASetupStep.EnterAuthenticatorName);
  });

  const { view: enterAuthenticatorNameView } = useMfaEnterAuthenticatorNameView(
    async name => {
      const resp = await mfaResource.createQrCode(currentUser.email, name);
      setQrCode(resp.data);
      setStep(MFASetupStep.ShowAuthenticatorQRCode);
    },
    () => setStep(MFASetupStep.SelectType),
  );

  const { view: showAuthenticatorQrCodeView } = useMfaAuthenticatorQrCodeView(qrCode, async () => {
    if (qrCode?.encoded === undefined) throw new Error('qrCode.encoded is missing');
    window.location.href = await mfaResource.getTotpConnectUrl({ totpUrl: qrCode.encoded });
  });

  const { view: showMfaAuthenticatorSuccessView } = useMfaAuthenticatorSuccessView(currentUser.email, () => {
    navigate(AppRoutes.homepage + '');
  });

  const { view: successSMSView } = useMfaSMSSuccessView(currentUser.email, () => {
    navigate(AppRoutes.homepage);
  });

  const calculatedStep = calcStep(step);

  return (
    <div className={`mfa-setup-page mfa-setup-page--${calculatedStep}`}>
      {calculatedStep === MFASetupStep.SelectType && selectTypeView}
      {calculatedStep === MFASetupStep.EnterPhoneNumber && (
        <MfaSMSPhoneNumberView
          onContinue={async (phoneNumber: string) => {
            // Ha NEM B2B mód, akkor ellenőrizzük a telefonszámot
            if (!isB2BMode) {
              await mfaResource.checkSmsNumber(phoneNumber);
            }
            // Mindkét esetben megkapjuk az SMS csatlakozási URL-t és továbbirányítunk
            const redirectUrl = await mfaResource.getSmsConnectUrl({ phoneNumber });
            window.location.href = redirectUrl;
          }}
          //onBack={() => (isB2BMode ? navigate(AppRoutes.homepage) : setStep(MFASetupStep.SelectType))}
          //SuccessSMS
          onBack={() => (isB2BMode ? navigate(AppRoutes.homepage) : setStep(MFASetupStep.SelectType))}
          boxConfig={{
            header: isB2BMode
              ? translation.accountSettings.mfa.smsSetup.titleb2b
              : translation.accountSettings.mfa.smsSetup.title,
            subHeader: isB2BMode
              ? translation.accountSettings.mfa.smsSetup.contentb2b
              : translation.accountSettings.mfa.smsSetup.content,
          }}
        />
      )}
      {calculatedStep === MFASetupStep.SuccessSMS && successSMSView}
      {calculatedStep === MFASetupStep.EnterAuthenticatorName && enterAuthenticatorNameView}
      {calculatedStep === MFASetupStep.ShowAuthenticatorQRCode && showAuthenticatorQrCodeView}
      {calculatedStep === MFASetupStep.SuccessAuthenticator && showMfaAuthenticatorSuccessView}
    </div>
  );
};
