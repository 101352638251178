import TagManager from '@sooro-io/react-gtm-module';

export const InitializeGTM = () => {
  try {
    const gtmId = (process.env as unknown as any).REACT_APP_GTM_KEY || 'GTM-K67SCRK';
    if (!gtmId) {
      console.warn('GTM ID not found');
      return;
    }
    const tagManagerArgs = {
      gtmId: gtmId,
    };

    TagManager.initialize(tagManagerArgs);
  } catch (e) {
    console.warn('Error initializing analytics', e);
  }
};

export type AnalyticsChannel = 'web' | 'app';
export type AnalyticsMethod = 'email' | 'sms' | 'devicecode';
export type EventName =
  | 'virtualPV'
  | 'buttonClick'
  | 'loginSuccess'
  // | 'login'
  | 'loginError'
  | 'loginFailed'
  | 'registrationSuccess'
  | 'registrationFailed'
  | 'registrationError';

export const AnalyticsEvent = (event: EventName, data?: Record<string, any>) => {
  try {
    TagManager.dataLayer({
      dataLayer: {
        event: event,
        ...data,
      },
    });
  } catch (e) {
    console.warn('Error sending analytics event', e);
  }
};

export const ClientId = () => {
  const u = new URL(window.location.href);
  const gcid = u.searchParams.get('goto')
    ? new URL(u.searchParams.get('goto') as string).searchParams.get('client_id')
    : undefined;
  if (gcid) {
    return gcid;
  } else {
    const cid = u.searchParams.get('client_id');
    return cid ? cid : undefined;
  }
};
export const AppOrWeb = (isWebView: boolean): AnalyticsChannel => (isWebView ? 'app' : 'web');

export const Analytics = {
  pageView: (isWebView: boolean, accountId: string | undefined = undefined) => {
    AnalyticsEvent('virtualPV', {
      channel: AppOrWeb(isWebView),
      clientId: ClientId(),
      location: window.location.href,
      accountId: accountId,
    });
  },

  buttonClick: (isWebView: boolean, linkText: string, accountId: string | undefined) => {
    AnalyticsEvent('buttonClick', {
      channel: AppOrWeb(isWebView),
      clientId: ClientId(),
      location: window.location.href,
      linkText: linkText,
      accountId: accountId,
    });
  },
};
