import { useQueryParams } from '@ytl/common-web';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useApp } from './contexts/appContext';
import { CurrentUserFetchState, selectCurrentFetchState, selectIsAuthenticated } from './contexts/currentUserSlicer';
import { ChangePasswordPage } from './pages/ChangePasswordPage';
import { EmailChangeBlockPage } from './pages/EmailChangeBlockPage';
import { EmailChangePage } from './pages/EmailChangePage';
import { EmailConfirmPage } from './pages/EmailConfirmPage';
import { ErrorPage } from './pages/ErrorPage';
import { ForgottenPasswordPage } from './pages/ForgottenPasswordPage';
import { HomePage } from './pages/HomePage';
import { InitialPasswordSetPage } from './pages/InitialPasswordSetPage';
import { MfaSetupPage } from './pages/MfaSetupPage';
import { NotConfirmedEmailPage } from './pages/NotConfirmedEmailPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { RegisterPage } from './pages/RegisterPage';
import { AppRoutes } from './routes';
import { Frame } from './shared/components/layout/frame/frame';
import { RegisterFrame } from './shared/components/layout/frame/registerFrame';

const UnAuthenticatedLayoutWithSession = ({
  page,
  disableHeader,
}: {
  page: React.ReactNode;
  disableHeader?: boolean;
}) => {
  return (
    <Frame
      children={page}
      isAuthenticated={false}
      disableHeader={disableHeader}
    />
  );
};

const AuthenticatedLayout = ({ page, disableHeader }: { page: React.ReactNode; disableHeader?: boolean }) => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const currentUserFetchState = useSelector(selectCurrentFetchState);
  const { isLoading } = useApp();

  useEffect(() => {
    if (!isAuthenticated && currentUserFetchState === CurrentUserFetchState.Loaded) {
      navigate(AppRoutes.errorPage);
    }
  }, [navigate, isAuthenticated, isLoading, currentUserFetchState]);

  return (
    <>
      {isAuthenticated && (
        <Frame
          children={page}
          disableHeader={disableHeader}
          isAuthenticated={true}
        />
      )}
    </>
  );
};

const RegisterLayout = ({ page }: { page: React.ReactNode }) => {
  return (
    <>
      <RegisterFrame children={page} />
    </>
  );
};

export const AppRouting = () => {
  const query = useQueryParams();
  const isRegistrationCompletedRawLayout = query.get('checkout') === 'true';
  return (
    <>
      <Routes>
        <Route
          path={AppRoutes.nonActiveAccount}
          element={<UnAuthenticatedLayoutWithSession page={<NotConfirmedEmailPage />} />}
        />
        <Route
          path={AppRoutes.initialPasswordSet}
          element={<UnAuthenticatedLayoutWithSession page={<InitialPasswordSetPage />} />}
        />
        <Route
          path={AppRoutes.errorPage}
          element={<UnAuthenticatedLayoutWithSession page={<ErrorPage />} />}
        />
        <Route
          path={AppRoutes.changePassword}
          element={<AuthenticatedLayout page={<ChangePasswordPage />} />}
        />
        <Route
          path={AppRoutes.forgottenPassword._}
          element={<UnAuthenticatedLayoutWithSession page={<ForgottenPasswordPage />} />}
        />
        <Route
          path={AppRoutes.forgottenPasswordSet}
          element={<UnAuthenticatedLayoutWithSession page={<ChangePasswordPage isForgottenPasswordSetMode={true} />} />}
        />
        <Route
          path={AppRoutes.emailConfirm}
          element={<UnAuthenticatedLayoutWithSession page={<EmailConfirmPage />} />}
        />
        <Route
          path={AppRoutes.emailChangeBlock}
          element={
            <UnAuthenticatedLayoutWithSession
              page={<EmailChangeBlockPage />}
              disableHeader={true}
            />
          }
        />
        <Route
          path={AppRoutes.register}
          element={<RegisterLayout page={<RegisterPage isRawLayout={false} />} />}
        />
        <Route
          path={AppRoutes.registrationCompleted}
          element={<RegisterLayout page={<RegisterPage isRawLayout={isRegistrationCompletedRawLayout} />} />}
        />
        <Route
          path={AppRoutes.mfaSetup}
          element={
            <AuthenticatedLayout
              page={<MfaSetupPage />}
              disableHeader={true}
            />
          }
        />
        <Route
          path={AppRoutes.emailChange}
          element={
            <AuthenticatedLayout
              page={<EmailChangePage />}
              disableHeader={true}
            />
          }
        />
        <Route
          path={AppRoutes.smsLoginError}
          element={<AuthenticatedLayout page={<HomePage />} />}
        />
        <Route
          path={AppRoutes.yoloRegister}
          element={<RegisterLayout page={<RegisterPage isRawLayout={false} />} />}
        />
        <Route
          path="/"
          element={<AuthenticatedLayout page={<HomePage />} />}
        />
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Routes>
    </>
  );
};
