import { useModeParams } from '@ytl/common-web';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useLanguage } from '../contexts/languageContext';
import { getConfig } from '../shared/data/config';

const useRecaptcha = () => {
  const [captchaToken, setCaptchaToken] = useState<string>('');
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);
  const { isApp } = useModeParams();
  const { language } = useLanguage();

  const recaptchaForceRender = useCallback(() => {
    // console.log('recaptchaForceRender, is token empty:', captchaToken==='');
    setCaptchaToken('');
    if (!recaptchaRef.current?.getWidgetId()) {
      setTimeout(() => {
        if (recaptchaRef?.current) {
          try {
            // console.log('explicitRender');
            // @ts-ignore
            recaptchaRef.current['explicitRender']();
          } catch (e) {
            console.error('explicitRender', e);
          }
        }
      }, 200);
    }
  }, [recaptchaRef, setCaptchaToken]);

  const handleRecaptcha = useCallback((token: string | null) => {
    setCaptchaToken(token || '');
  }, []);

  const needsCaptcha = useMemo(() => {
    return isApp ? getConfig().reCaptchaAppEnabled : getConfig().reCaptchaDesktopEnabled;
  }, [isApp]);

  const canContinueByCaptcha = useMemo(() => {
    const hasCaptcha = captchaToken !== '';
    // console.log('canContinueByCaptcha', res, hasCaptcha, needsCaptcha);
    return (needsCaptcha && hasCaptcha) || !needsCaptcha;
  }, [captchaToken, needsCaptcha]);

  useEffect(() => {
    const timeout = (function (ct: string) {
      if (ct) {
        return setTimeout(() => {
          if (recaptchaRef?.current && ct) {
            recaptchaRef?.current.reset();
            setCaptchaToken('');
          }
        }, 100000);
      }
    })(captchaToken);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [captchaToken]);
  const captchaWidgetReset = useCallback(() => {
    recaptchaRef.current?.reset();
    setCaptchaToken('');
  }, [recaptchaRef, setCaptchaToken]);

  return {
    captchaToken,
    setCaptchaToken,
    recaptchaRef,
    handleRecaptcha,
    canContinueByCaptcha,
    needsCaptcha,
    recaptchaForceRender,
    recaptchaLang: language,
    recaptchaSiteKey: getConfig().reCaptchaSiteKey,
    captchaWidgetReset,
  };
};

export default useRecaptcha;
