import { Box, BoxProps, Button, Input, onEnter, Tooltip, usePhoneNumberMask } from '@ytl/common-web';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '../../../assets/info.svg';
import { useLanguage } from '../../../contexts/languageContext';

export const MfaSMSPhoneNumberView = ({
  onContinue,
  onBack,
  boxConfig: propBoxConfig,
}: {
  onContinue: (secondaryPhoneNumber: string) => Promise<void>;
  onBack: () => void;
  boxConfig?: {
    header: string;
    subHeader: string;
  };
}) => {
  const { translation } = useLanguage();
  // const navigate = useNavigate();
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const phoneNumberInput = usePhoneNumberMask();

  const continueClicked = useCallback(async () => {
    if (isLoading || secondaryPhoneNumber.length !== 11) {
      return;
    }
    try {
      setError('');
      setIsLoading(true);
      await onContinue(secondaryPhoneNumber.replaceAll(' ', ''));
    } catch (err) {
      setError(translation.accountSettings.mfa.smsSetup.failedToSend);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, setIsLoading, secondaryPhoneNumber, onContinue, translation]);

  const boxConfig: Omit<BoxProps, 'children'> = useMemo(() => {
    return {
      header: propBoxConfig?.header || translation.accountSettings.mfa.smsSetup.title,
      subHeader: propBoxConfig?.subHeader || translation.accountSettings.mfa.smsSetup.content,
      action: (
        <Button
          disabled={isLoading || secondaryPhoneNumber.length !== 11}
          onClick={() => continueClicked()}
        >
          {translation.accountSettings.mfa.smsSetup.continue}
        </Button>
      ),
      subAction: (
        <Button
          variant="text"
          onClick={() => onBack()}
          disabled={isLoading}
        >
          {translation.accountSettings.mfa.smsSetup.cancel}
        </Button>
      ),
    };
  }, [translation, continueClicked, isLoading, secondaryPhoneNumber, onBack, propBoxConfig]);

  return (
    <Box {...boxConfig}>
      <div className="phone-number-input">
        <Input
          label={
            <>
              {translation.accountSettings.mfa.smsSetup.phoneNumberLabel}
              <Tooltip
                html={translation.accountSettings.mfa.smsSetup.phoneNumberLabelTooltip}
                icon={<InfoIcon className={'icon icon--tooltip'} />}
                includeIconInTooltip={true}
              />
            </>
          }
          name={'secondaryPhoneNumber'}
          type={'text'}
          value={secondaryPhoneNumber}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSecondaryPhoneNumber(e.target.value)}
          disabled={isLoading}
          errorList={error ? [error] : []}
          maxLength={11}
          onKeyUp={onEnter(continueClicked, () => setError(''))}
          ref={phoneNumberInput}
        />
      </div>
    </Box>
  );
};
