import { Dialog, DialogCloseReason, DialogProps, DialogWrapperProps, HeaderMenuItem } from '@ytl/common-web';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AppRoutes } from '../routes';
import { determineAppMode } from '../shared/utils/appMode';
import { useLanguage } from './languageContext';

export type AppContextProps =
  | undefined
  | {
      isLoading: boolean;
      setLoading: (value: boolean) => void;
      menuItems: Array<HeaderMenuItem>;
      setMenuItems: (value: Array<HeaderMenuItem>) => void;
      openDialog: (props: DialogProps) => Promise<DialogCloseReason>;
      registerAccountId: string;
      setRegisterAccountId: (accountId: string) => void;
    };

export const AppContext = createContext<AppContextProps>(undefined);
const isB2B = determineAppMode() === 'b2b';

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const { translation } = useLanguage();
  const [dialogConfig, setDialogConfig] = useState<DialogWrapperProps | undefined>();

  const openDialog = (props: DialogProps) => {
    return new Promise<DialogCloseReason>(resolve => {
      setDialogConfig({
        ...props,
        onClose: (reason: DialogCloseReason) => {
          resolve(reason);
          setDialogConfig(undefined);
        },
      });
    });
  };

  /*
  const defaultMenuItems = useMemo(() => {
    return [
      isB2B && {
        //label: translation.header.menu.backToYettel,
          label: 'blablabla',
        onClick() {
          AppRoutes.goToYettel();
        },
      },
    ];
  }, [translation]);
*/
  const defaultMenuItems = useMemo(() => {
    return isB2B
      ? [
          {
            label: translation.header.menu.backToYettelB2B,
            onClick() {
              AppRoutes.goToYettelB2B();
            },
          },
        ]
      : [
          {
            label: translation.header.menu.backToYettel,
            onClick() {
              AppRoutes.goToYettel();
            },
          },
        ];
  }, [translation]);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [displayedMenuItems, setDisplayedMenuItems] = useState<Array<HeaderMenuItem>>();
  // const [menuItems, setMenuItems] = useState<Array<HeaderMenuItem>>(defaultMenuItems);
  const [registerAccountId, setRegisterAccountId] = useState<string>('');

  useEffect(() => {}, [translation]);

  const menuItems = useMemo(() => {
    return displayedMenuItems || defaultMenuItems;
  }, [displayedMenuItems, defaultMenuItems]);

  const setMenuItems = useCallback(
    (list: Array<HeaderMenuItem>) => {
      setDisplayedMenuItems(list);
    },
    [setDisplayedMenuItems],
  );

  return (
    <AppContext.Provider
      value={{
        isLoading,
        setLoading,
        menuItems,
        setMenuItems,
        openDialog,
        registerAccountId,
        setRegisterAccountId,
      }}
    >
      {children}
      {dialogConfig && <Dialog {...dialogConfig} />}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error(`useApp must be used within an AppProvider`);
  }
  return context;
};
