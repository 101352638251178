import { UserDTO, UserOAuthConnection } from '@ytl/common-model';
import { Button, errorIcon, successIcon } from '@ytl/common-web';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AppleIcon } from '../../../assets/icon/apple.svg';
import { ReactComponent as FacebookIcon } from '../../../assets/icon/facebook.svg';
import { ReactComponent as GoogleIcon } from '../../../assets/icon/google.svg';
import { useApp } from '../../../contexts/appContext';
import { selectCurrentUser, setCurrentUserData, updateOauthData } from '../../../contexts/currentUserSlicer';
import { useLanguage } from '../../../contexts/languageContext';
import { AppRoutes } from '../../../routes';

import { getConfig } from '../../data/config';
import {
  accountSaveErrorDialogProps,
  confirmChangesDialogProps,
  emailSentDialogProps,
  nameSaveSuccessDialogProps,
} from '../../data/dialogConfig';
import { accountResource } from '../../resource/account.resource';
import { authResource } from '../../resource/auth.resource';
import { socialResource } from '../../resource/social.resource';
import { isValidEmail } from '../../utils/emailValidator';
import { AccountDataEditor, AccountDataEditorType, ViewMode } from '../custom/account-data-editor/AccountDataEditor';
import { DialogCloseReason } from '../custom/dialog/Dialog';
import { DataCard } from '../ui/data-card/DataCard';
import { InfoCard } from '../ui/info-card/InfoCard';

export type AccountSettingsProps = {
  onSetupMFA: () => void;
  onDisconnectMFA: () => void;
  isB2BMode?: boolean;
};

export const AccountSettings = ({ onSetupMFA, onDisconnectMFA, isB2BMode = false }: AccountSettingsProps) => {
  const { translation } = useLanguage();
  const dispatch = useDispatch();
  const { openDialog } = useApp();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser) as UserDTO;
  const [accountName, setAccountName] = useState(currentUser.name);
  const [accountEmail] = useState(currentUser.email);
  const [valueChanges, setValueChanges] = useState({ name: false, email: false });
  const [viewModes, setViewModes] = useState<Record<AccountDataEditorType, ViewMode>>({
    name: ViewMode.View,
    email: ViewMode.View,
  });
  const [oauthActionLoading, setOauthActionLoading] = useState<boolean>(false);

  const saveAccountName = useCallback(
    async (newName: string) => {
      const errors = [];
      if (newName.length === 0) {
        errors.push(translation.accountSettings.accountDataCard.errors.emptyName);
      }
      if (errors.length) {
        return errors;
      }
      return accountResource.updateAccountName(newName, currentUser.accountId);
    },
    [currentUser, translation],
  );

  const saveEmail = useCallback(
    async (newEmail: string) => {
      const errors = [];
      if (newEmail.length === 0) {
        errors.push(translation.accountSettings.accountDataCard.errors.emptyEmail);
      }
      if (!isValidEmail(newEmail)) {
        errors.push(translation.accountSettings.accountDataCard.errors.invalidEmail);
      }
      if (errors.length) {
        return errors;
      }
      return accountResource.updateEmail(newEmail, currentUser.accountId);
    },
    [currentUser, translation],
  );

  const canModify = useCallback(
    async (type: AccountDataEditorType) => {
      const otherEditorType: AccountDataEditorType = type === 'email' ? 'name' : 'email';
      if (viewModes[otherEditorType] === ViewMode.Edit && valueChanges[otherEditorType]) {
        const dialogRes = await openDialog(confirmChangesDialogProps(translation));
        if (dialogRes === DialogCloseReason.Ok) {
          return false;
        }
      }
      setValueChanges({ name: false, email: false });
      setViewModes({ ...viewModes, [type]: ViewMode.Edit, [otherEditorType]: ViewMode.View });
      return true;
    },
    [openDialog, translation, valueChanges, viewModes],
  );

  const onCancel = useCallback(
    async (type: AccountDataEditorType) => {
      if (valueChanges[type]) {
        const res = await openDialog(confirmChangesDialogProps(translation));
        if (res !== DialogCloseReason.Cancel) {
          return false;
        }
      }
      setValueChanges({ name: false, email: false });
      setViewModes({ ...viewModes, [type]: ViewMode.View });
      return true;
    },
    [openDialog, translation, valueChanges, viewModes],
  );

  const onSave = useCallback(
    async (type: AccountDataEditorType, newValue: string, newValue2?: string) => {
      const saveFn = type === 'email' ? saveEmail : saveAccountName;
      if (type === 'email') {
        if (!isValidEmail(newValue)) {
          return [translation.emailChange.error.invalidEmail];
        }
        if (newValue !== newValue2) {
          return [translation.emailChange.error.emailMismatch];
        }

        if (newValue === accountEmail) {
          setViewModes({ ...viewModes, [type]: ViewMode.View });
          return true;
        }
      } else {
        if (newValue === accountName) {
          setViewModes({ ...viewModes, [type]: ViewMode.View });
          return true;
        }
        if (!newValue.match(/^[\p{Letter}\p{Mark}]+([_\\. \\-\\,][\p{Letter}\p{Mark}]+)*$/u)) {
          return [translation.accountSettings.accountDataCard.nameFormat];
        }
      }

      try {
        const res = await saveFn(newValue);
        if (res instanceof Array) {
          return res;
        }
        if (res) {
          if (type === 'email') {
            accountResource.fullLogout();
            await openDialog(emailSentDialogProps(translation));
            window.location.href = AppRoutes.homepage;
          } else {
            await openDialog(nameSaveSuccessDialogProps(translation));
            setAccountName(newValue);
          }
          setValueChanges({ name: false, email: false });
          setViewModes({ ...viewModes, [type]: ViewMode.View });

          const accountData = await authResource.getAccount();
          dispatch(setCurrentUserData(accountData));
          return true;
        } else {
          // unknown error
          throw new Error();
        }
      } catch {
        await openDialog(accountSaveErrorDialogProps(translation));
        return false;
      }
    },
    [accountEmail, accountName, dispatch, openDialog, saveAccountName, saveEmail, translation, viewModes],
  );

  const onChange = useCallback(
    async (type: AccountDataEditorType) => {
      setValueChanges({ ...valueChanges, [type]: true });
    },
    [valueChanges],
  );

  const deleteAccount = useCallback(async () => {
    if (currentUser.assignedSubscriptions.find(s => s.tag?.includes('xtv'))) {
      await openDialog({
        icon: errorIcon.icon,
        title: translation.dialogSet.deleteAccountDialog.cantDeleteTitle,
        content: (
          <span
            dangerouslySetInnerHTML={{
              __html: translation.dialogSet.deleteAccountDialog.tvContent(getConfig().videoChatUrl),
            }}
          />
        ),
        okLabel: translation.dialogSet.deleteAccountDialog.cantDeleteConfirm,
      });
      return;
    }
    if (currentUser.assignedSubscriptions.find(s => s.tag?.includes('yolo'))) {
      // show dialog saying you can't delete your account because you have a YOLO account
      await openDialog({
        icon: errorIcon.icon,
        title: translation.dialogSet.deleteAccountDialog.cantDeleteTitle,
        content: (
          <span
            dangerouslySetInnerHTML={{
              __html: translation.dialogSet.deleteAccountDialog.yoloContent(getConfig().videoChatUrl),
            }}
          />
        ),
        okLabel: translation.dialogSet.deleteAccountDialog.cantDeleteConfirm,
      });
      return;
    }

    const res = await openDialog({
      title: translation.dialogSet.deleteAccountDialog.title,
      subHeader: translation.dialogSet.deleteAccountDialog.content,
      cancelLabel: translation.dialogSet.deleteAccountDialog.cancelLabel,
      dangerLabel: translation.dialogSet.deleteAccountDialog.dangerLabel,
    });
    if (res === DialogCloseReason.Danger) {
      try {
        await accountResource.deleteAccount(currentUser.accountId);
        await openDialog({
          icon: successIcon.icon,
          title: translation.dialogSet.deleteAccountDialog.successTitle,
          content: translation.dialogSet.deleteAccountDialog.successContent,
          okLabel: translation.dialogSet.deleteAccountDialog.successConfirm,
        });
        AppRoutes.goToYettel();
      } catch {
        await openDialog({
          icon: errorIcon.icon,
          titleHTML: translation.dialogSet.deleteAccountDialog.errorTitle,
          okLabel: translation.dialogSet.deleteAccountDialog.errorConfirm,
        });
      }
    }
  }, [currentUser, openDialog, translation]);

  const getSocialConnectUrl = useCallback((provider: keyof UserOAuthConnection) => {
    switch (provider) {
      case 'googleAuth':
        return socialResource.connectUrl('google');
      case 'facebookAuth':
        return socialResource.connectUrl('facebook');
      case 'appleIdAuth':
        return socialResource.connectUrl('apple');
      default:
        throw new Error('Invalid provider ' + provider);
    }
  }, []);

  const connectDisconnect = useCallback(
    async (provider: keyof UserOAuthConnection) => {
      if (currentUser.oauthConnection && currentUser.oauthConnection[provider]) {
        try {
          setOauthActionLoading(true);
          const res = await accountResource.disconnectOauthAccount(currentUser.accountId, provider);
          dispatch(updateOauthData(res.data?.oauthConnection));
        } catch (err) {
          console.error('Error disconnecting oauth account', err);
          await openDialog(accountSaveErrorDialogProps(translation));
        } finally {
          setOauthActionLoading(false);
        }
      } else {
        try {
          setOauthActionLoading(true);
          const res = await getSocialConnectUrl(provider);
          if (res) {
            window.location.href = res;
          } else {
            console.error('No connect url found for provider ' + provider);
            await openDialog(accountSaveErrorDialogProps(translation));
          }
        } catch (err) {
          console.error('Error getting connect url for provider ' + provider, err);
          await openDialog(accountSaveErrorDialogProps(translation));
        } finally {
          setOauthActionLoading(false);
        }
      }
    },
    [currentUser, dispatch, getSocialConnectUrl, openDialog, translation],
  );

  const socialProviderButtons = useMemo(() => {
    if (!getConfig().AllowSocialProviders) {
      return <></>;
    }
    return (
      <>
        <Button
          variant={'outlined'}
          onClick={() => connectDisconnect('facebookAuth')}
          disabled={oauthActionLoading}
        >
          {currentUser.oauthConnection?.facebookAuth
            ? translation.accountSettings.oauth.disconnect.facebook
            : translation.accountSettings.oauth.connect.facebook}
          <FacebookIcon />
        </Button>
        <Button
          variant={'outlined'}
          onClick={() => connectDisconnect('googleAuth')}
          disabled={oauthActionLoading}
        >
          {currentUser.oauthConnection?.googleAuth
            ? translation.accountSettings.oauth.disconnect.google
            : translation.accountSettings.oauth.connect.google}
          <GoogleIcon />
        </Button>
        <Button
          variant={'outlined'}
          onClick={() => connectDisconnect('appleIdAuth')}
          disabled={oauthActionLoading}
          style={{ display: 'none' }}
        >
          {currentUser.oauthConnection?.appleIdAuth
            ? translation.accountSettings.oauth.disconnect.apple
            : translation.accountSettings.oauth.connect.apple}
          <AppleIcon />
        </Button>
      </>
    );
  }, [translation, currentUser, oauthActionLoading, connectDisconnect]);

  useEffect(() => {
    if (window.location.pathname === AppRoutes.smsLoginError) {
      accountResource.fullLogout().catch(reason => console.error('error during logout', reason));
    }
  }, []);

  useLayoutEffect(() => {
    if (window.location.pathname === AppRoutes.smsLoginError) {
      openDialog({
        ...errorIcon,
        titleHTML: translation.accountSettings.smsLoginError.title,
        content: translation.accountSettings.smsLoginError.content,
        okLabel: translation.accountSettings.smsLoginError.primaryAction,
        cancelLabel: translation.accountSettings.smsLoginError.secondaryAction,
        verticalActions: true,
        cancelButtonVariant: 'text-undecorated',
      }).then(res => {
        if (res === DialogCloseReason.Ok) {
          window.location.href = AppRoutes.homepage;
        } else if (res === DialogCloseReason.Cancel) {
          window.location.href = getConfig().smsLoginErrorBackToYettelUrl;
        }
      });
    }
  }, [openDialog, translation]);

  return (
    <div className="account-settings">
      {!isB2BMode && (
        <DataCard header={translation.accountSettings.accountDataCard.header}>
          <div className="account-data account-data--account-name">
            <AccountDataEditor
              label={translation.accountSettings.accountDataCard.nameLabel}
              label2={translation.accountSettings.accountDataCard.nameLabel}
              value={accountName || ''}
              type={'name'}
              viewMode={viewModes.name}
              canModify={canModify}
              onSave={onSave}
              canCancel={onCancel}
              onValueChange={onChange}
            />
          </div>

          <div className="account-data account-data--email-address">
            <AccountDataEditor
              label={translation.accountSettings.accountDataCard.emailLabel}
              label2={translation.accountSettings.accountDataCard.emailLabelAgain}
              value={accountEmail}
              type={'email'}
              viewMode={viewModes.email}
              canModify={canModify}
              onSave={onSave}
              canCancel={onCancel}
              onValueChange={onChange}
              showModify={
                !currentUser.contactId && !currentUser.assignedSubscriptions.find(s => s.tag?.includes('otp-verified'))
              }
            />
          </div>

          {/*<div className="account-data account-data--reg-type">*/}
          {/*  <legend>{translation.accountSettings.accountDataCard.registrationType}</legend>*/}
          {/*  <p>{translation.accountSettings.accountDataCard.registrationTypeEmail}</p>*/}
          {/*</div>*/}
        </DataCard>
      )}

      <DataCard header={translation.accountSettings.passwordDataCard.header}>
        <div className="account-data account-data--password-info">
          <div>
            <legend>{translation.accountSettings.passwordDataCard.passwordLabel}</legend>
            <p>{translation.accountSettings.passwordDataCard.passwordValue}</p>
          </div>
          <div className={'account-data__button-row'}>
            <Button
              onClick={() => navigate(AppRoutes.changePassword)}
              variant={'text'}
            >
              {translation.accountSettings.passwordDataCard.modifyButton}
            </Button>
          </div>
        </div>
      </DataCard>

      {getConfig().mfaEnabled && (
        <DataCard header={translation.accountSettings.oauth.header}>
          <div className="alm-button-container">
            {socialProviderButtons}

            {currentUser.isMfaSetup && !isB2BMode && (
              <Button
                variant={'text'}
                onClick={() => {
                  try {
                    onDisconnectMFA();
                    const newUser = { ...currentUser, isMfaSetup: false };
                    dispatch(setCurrentUserData(newUser));
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                {translation.accountSettings.mfa.disconnectAction}
              </Button>
            )}
            {currentUser.isMfaSetup && isB2BMode && (
              <Button
                variant={'outlined'}
                onClick={() => onSetupMFA()}
              >
                {translation.accountSettings.mfa.modifyAction}
              </Button>
            )}
            {!currentUser.isMfaSetup && !isB2BMode && (
              <Button
                variant={'outlined'}
                onClick={() => onSetupMFA()}
              >
                {translation.accountSettings.mfa.setupAction}
              </Button>
            )}
          </div>
        </DataCard>
      )}
      {!isB2BMode && !currentUser.contactId && (
        <DataCard header={translation.accountSettings.deleteAccountCard.header}>
          <div className="alm-button-container">
            <Button
              onClick={deleteAccount}
              variant={'contained'}
            >
              {translation.accountSettings.deleteAccountCard.deleteButton}
            </Button>
          </div>
        </DataCard>
      )}
      {isB2BMode && (
        <InfoCard header={translation.accountSettings.b2bInfoCard.header}>
          <div className="info-card-content">
            <p
              className="info-text"
              dangerouslySetInnerHTML={{ __html: translation.accountSettings.b2bInfoCard.title }}
            />
          </div>
        </InfoCard>
      )}
    </div>
  );
};
