import { DialogProps } from '@ytl/common-web';
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import { ReactComponent as EnvelopeIcon } from '../../assets/envelope.svg';
import { ReactComponent as ErrorIcon } from '../../assets/error.svg';
import { Translation } from '../../contexts/languageContext';

export const confirmLeavingPageDialogProps = (translation: Translation): Omit<DialogProps, 'children'> => ({
  title: translation.dialogSet.leaveDialog.title,
  subHeader: translation.dialogSet.leaveDialog.content,
  okLabel: translation.dialogSet.leaveDialog.okLabel,
  cancelLabel: translation.dialogSet.leaveDialog.cancelLabel,
});

export const confirmChangesDialogProps = (translation: Translation): Omit<DialogProps, 'children'> => ({
  title: translation.dialogSet.changeDialog.title,
  subHeader: translation.dialogSet.changeDialog.content,
  okLabel: translation.dialogSet.changeDialog.okLabel,
  cancelLabel: translation.dialogSet.changeDialog.cancelLabel,
});

export const emailSentDialogProps = (translation: Translation): Omit<DialogProps, 'children'> => ({
  icon: <EnvelopeIcon className={'icon icon--envelope'} />,
  title: translation.dialogSet.emailChangeSuccessDialog.title,
  subHeader: translation.dialogSet.emailChangeSuccessDialog.content,
  cancelLabel: translation.dialogSet.emailChangeSuccessDialog.cancelLabel,
});

export const nameSaveSuccessDialogProps = (translation: Translation): Omit<DialogProps, 'children'> => ({
  icon: <CheckIcon className={'icon icon--success'} />,
  title: translation.dialogSet.successDialog.title,
  subHeader: translation.dialogSet.successDialog.subHeader,
  okLabel: translation.dialogSet.successDialog.okLabel,
});

export const accountSaveErrorDialogProps = (translation: Translation): Omit<DialogProps, 'children'> => ({
  icon: <ErrorIcon className={'icon icon--error'} />,
  titleHTML: translation.dialogSet.errorDialog.title,
  subHeader: translation.dialogSet.errorDialog.content,
  okLabel: translation.dialogSet.errorDialog.okLabel,
});

export const registerErrorDialogProps = (translation: Translation): Omit<DialogProps, 'children'> => ({
  icon: <ErrorIcon className={'icon icon--error'} />,
  titleHTML: translation.dialogSet.errorDialog.title,
  subHeader: translation.loginRegister.register.box.error,
  okLabel: translation.dialogSet.errorDialog.okLabel,
});
