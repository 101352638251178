import { ReactElement } from 'react';

export interface InfoCardProps {
  header: string | ReactElement;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export const InfoCard = ({ header, children, style }: InfoCardProps) => {
  return (
    <div
      className="info-card"
      style={style || {}}
    >
      <div className="info-card__header">{header}</div>
      <div className="info-card__content">{children}</div>
    </div>
  );
};
