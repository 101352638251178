import { Fragment, useMemo } from 'react';
import YettelB2BLogo from '../../assets/yettel_logo_b2b.svg';
import YettelB2CLogo from '../../assets/yettel_logo_b2c.svg';
import { Button } from '../button/Button';
import { Language } from '../context';

export interface HeaderMenuItem {
  label: string;
  onClick: () => void;
  $selected?: boolean;
}

export interface HeaderProps {
  mode: 'b2c' | 'b2b';
  menuItems: Array<HeaderMenuItem>;
  language: Language;
  onLanguageClick: () => void;
}

export const Header = ({ mode, menuItems, language, onLanguageClick }: HeaderProps) => {
  const cssClass = useMemo(() => {
    if (mode === 'b2b') {
      return 'b2b';
    }
    return 'b2c';
  }, [mode]);

  const displayedLanguage = useMemo(() => {
    if (language === 'en') {
      return 'hu';
    }
    return 'en';
  }, [language]);
  // console.log(cssClass);
  return (
    <>
      <input
        id="menuSwitch"
        className="header__switch"
        type="checkbox"
      />
      <header className={`header header--${cssClass}`}>
        <div className="header__logo">{mode === 'b2c' ? <YettelB2CLogo /> : <YettelB2BLogo />}</div>
        <nav className="header__menu-items">
          <ul>
            {(menuItems || []).map((menuItem, index) => (
              <Fragment key={index}>
                <li
                  onClick={menuItem.onClick}
                  className={`header__menu-item ${menuItem.$selected ? `header__menu-item--${cssClass}--selected` : `header__menu-item--${cssClass}`}`}
                >
                  {menuItem.label}
                </li>
              </Fragment>
            ))}
          </ul>
        </nav>
        <div className="header__fill"></div>
        <div className="header__switch-language">
          <Button
            className={`button--${cssClass}`}
            variant={'text'}
            onClick={onLanguageClick}
          >
            {displayedLanguage.toUpperCase()}
          </Button>
        </div>
        <div className="header__mobile-toggle">
          <label htmlFor="menuSwitch">
            <span className={`ham ham--${cssClass}--1`} />
            <span className={`ham ham--${cssClass}--2`} />
            <span className={`ham ham--${cssClass}--3`} />
          </label>
        </div>
      </header>
    </>
  );
};
