import { accountIdParam, MIDDLEWARE_ERROR_CODES, RECAPTCHA_ERROR_CODES, RegistrationMode } from '@ytl/common-model';
import { useModeParams, useQueryParams, useTheme } from '@ytl/common-web';
import axios from 'axios';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useApp } from '../../../contexts/appContext';
import { useLanguage } from '../../../contexts/languageContext';
import { RegistrationPhase } from '../../../pages/RegisterPage';
import { AppRoutes } from '../../../routes';
import { accountResource } from '../../../shared/resource/account.resource';
import { authResource } from '../../../shared/resource/auth.resource';
import { ALMAnalytics } from '../../../shared/utils/analytics';
import { isValidEmail } from '../../../shared/utils/emailValidator';
import { usePassword } from '../../../shared/utils/usePassword';

export const useRegister = (registrationMode: RegistrationMode) => {
  const { translation } = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [registerError, setRegisterError] = useState('');
  const [view, setView] = useState(RegistrationPhase.Form);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState<boolean>(false);
  const [needsRedirect, setNeedsRedirect] = useState(false);

  const _usePassword = usePassword();
  const { password, validatePasswords } = _usePassword;
  const { setRegisterAccountId } = useApp();
  const location = useLocation();
  const query = useQueryParams();
  const { isWebView } = useTheme();
  const { isYolo } = useModeParams();
  const [searchParams] = useSearchParams();

  useLayoutEffect(() => {
    if (location.pathname === AppRoutes.yoloRegister) {
      setIsLoading(true);
      // debugger;
      const search = new URLSearchParams(location.search);
      if (search.has(accountIdParam)) {
        setIsLoading(false);
        setRegisterAccountId(search.get(accountIdParam)!);
        setView(RegistrationPhase.EmailOTP);
      } else {
        accountResource
          .getYoloRegAuthUrl()
          .then(data => {
            setNeedsRedirect(true);
            window.location.href = data;
          })
          .catch(_err => {
            setIsLoading(false);
            setView(RegistrationPhase.RegistrationFailed);
          });
      }
    }
  }, [location, setRegisterAccountId]);

  useEffect(() => {
    if (location.pathname === AppRoutes.registrationCompleted) {
      if (query.get('completed') === 'true') {
        authResource
          .getAccount(false)
          .then(data => {
            ALMAnalytics.registerSuccess(isWebView, isYolo, data?.accountId);
          })
          .catch(err => {
            console.warn('Failed to fetch account data after registration.', err);
          });
        setView(RegistrationPhase.RegistrationComplete);
      } else {
        ALMAnalytics.registerFailed(isWebView, isYolo, MIDDLEWARE_ERROR_CODES.UNKNOWN_BACKEND_ERROR);
        setView(RegistrationPhase.RegistrationFailed);
      }
    }
  }, [location, query, isYolo, isWebView, setRegisterAccountId, setNeedsRedirect]);

  const register = useCallback(
    async (captchaToken: string, captchaReset: () => void) => {
      let validationFailure = false;
      if (!validatePasswords()) {
        validationFailure = true;
      }
      if (!isValidEmail(email)) {
        setEmailError(true);
        validationFailure = true;
      } else {
        setEmailError(false);
      }
      if (validationFailure) {
        setRegisterError('');
        setEmailAlreadyUsed(false);
        return;
      }

      try {
        setIsLoading(true);
        setRegisterError('');
        setEmailAlreadyUsed(false);

        const nameToStore = !!name ? name : email;
        const accountId = await accountResource.registerAccount({
          mode: registrationMode,
          name: nameToStore,
          email,
          password,
          ct: captchaToken,
        });
        captchaReset();
        if (accountId) {
          setRegisterAccountId(accountId);
        } else {
          setRegisterError(translation.loginRegister.register.box.error);
        }
        if (registrationMode === RegistrationMode.Yolo) {
          setView(RegistrationPhase.EmailOTP);
        } else {
          setView(RegistrationPhase.Result);
        }
      } catch (err) {
        captchaReset();

        if (axios.isAxiosError(err)) {
          const responseData = (err.response?.data as { message: string }).message;
          if (responseData === MIDDLEWARE_ERROR_CODES.EMAIL_ADDRESS_NOT_AVAILABLE) {
            ALMAnalytics.registerError(isWebView, isYolo, MIDDLEWARE_ERROR_CODES.EMAIL_ADDRESS_NOT_AVAILABLE);
            setEmailAlreadyUsed(true);
          } else if (responseData === MIDDLEWARE_ERROR_CODES.INVALID_EMAIL_FORMAT) {
            ALMAnalytics.registerError(isWebView, isYolo, MIDDLEWARE_ERROR_CODES.INVALID_EMAIL_FORMAT);
            setEmailError(true);
          } else if (
            responseData === RECAPTCHA_ERROR_CODES.TOKEN_INVALID ||
            responseData === RECAPTCHA_ERROR_CODES.TOKEN_MISSING
          ) {
            ALMAnalytics.registerError(isWebView, isYolo, responseData);
            setRegisterError(translation.loginRegister.register.box.error);
          } else {
            ALMAnalytics.registerFailed(isWebView, isYolo, MIDDLEWARE_ERROR_CODES.UNKNOWN_BACKEND_ERROR);
            setRegisterError(translation.loginRegister.register.box.error);
          }
        } else {
          ALMAnalytics.registerFailed(isWebView, isYolo, MIDDLEWARE_ERROR_CODES.UNKNOWN_BACKEND_ERROR);
          setRegisterError(translation.loginRegister.register.box.error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [email, name, password, registrationMode, translation, setRegisterAccountId, validatePasswords, isYolo, isWebView],
  );

  const updateEmail = useCallback((email: string) => {
    setEmail(email);
    setEmailError(false);
    setEmailAlreadyUsed(false);
  }, []);

  const yoloResend = async (registerAccountId: string | undefined) => {
    const hasAccountId = async (accountId: string) => {
      try {
        setIsLoading(true);
        await accountResource.resendOtp(accountId);
      } finally {
        setIsLoading(false);
      }
    };

    if (location.pathname.startsWith(AppRoutes.yoloRegister) && searchParams.has(accountIdParam)) {
      await hasAccountId(searchParams.get(accountIdParam)!);
    } else if (registerAccountId) {
      await hasAccountId(registerAccountId);
    } else {
      setView(RegistrationPhase.Form);
    }
  };

  return {
    isLoading,
    setIsLoading,
    registerError,
    view,
    setView,
    name,
    setName,
    password,
    email,
    setEmail: updateEmail,
    emailError,
    emailAlreadyUsed,
    register,
    _usePassword,
    needsRedirect,
    yoloResend,
    setNeedsRedirect,
  };
};
