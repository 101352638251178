import { RegistrationMode } from '@ytl/common-model';
import { Button, Input, InputMaxPasswordLength, Tooltip } from '@ytl/common-web';
import { Fragment, ReactNode, useCallback, useEffect, useMemo } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '../../assets/check.svg';
import { ReactComponent as Envelope } from '../../assets/envelope.svg';
import { ReactComponent as ErrorIcon } from '../../assets/error.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icon/facebook.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icon/google.svg';
import { ReactComponent as InfoIcon } from '../../assets/info.svg';
import { useApp } from '../../contexts/appContext';
import { useLanguage } from '../../contexts/languageContext';
import { RegistrationPhase } from '../../pages/RegisterPage';
import { AppRoutes } from '../../routes';
import { getConfig } from '../../shared/data/config';
import { registerErrorDialogProps } from '../../shared/data/dialogConfig';
import { accountResource } from '../../shared/resource/account.resource';
import useRecaptcha from '../useRecaptcha';
import { RegisterYoloOtpView } from './RegisterYoloOtpView';
import { useRegister } from './service/useRegister';

export type RegisterViewProps = {
  registrationMode: RegistrationMode;
};

export const RegisterView = ({ registrationMode }: RegisterViewProps) => {
  const { translation } = useLanguage();
  const {
    isLoading,
    setIsLoading,
    registerError,
    view,
    setView,
    name,
    setName,
    email,
    setEmail,
    emailError,
    emailAlreadyUsed,
    register,
    _usePassword,
    yoloResend,
  } = useRegister(registrationMode);
  const {
    password,
    setPassword,
    passwordAgain,
    setPasswordAgain,
    getPasswordHints,
    passwordsAreNotMatching,
    hasPasswordError,
    isContinueButtonDisabled,
  } = _usePassword;
  const navigate = useNavigate();
  const { openDialog } = useApp();

  const handleRegistrationError = useCallback(async () => {
    if (registerError) {
      // Megnyitjuk a hibadialógust
      await openDialog(registerErrorDialogProps(translation));
    }
    // eslint-disable-next-line
  }, [registerError, translation]);

  useEffect(() => {
    if (registerError) {
      (async () => {
        await handleRegistrationError(); // Meghívjuk a hibakezelő callbacket
      })();
    }
  }, [registerError, handleRegistrationError]);

  const { registerAccountId } = useApp();
  const {
    canContinueByCaptcha,
    needsCaptcha,
    recaptchaRef,
    recaptchaForceRender,
    recaptchaSiteKey,
    recaptchaLang,
    handleRecaptcha,
    captchaToken,
    captchaWidgetReset,
  } = useRecaptcha();

  const registerButtonEnabled = useMemo(() => {
    return email.length > 0 && !isContinueButtonDisabled && canContinueByCaptcha;
  }, [email, isContinueButtonDisabled, canContinueByCaptcha]);

  const orSection: Array<{ children: ReactNode; key: string }> = useMemo(() => {
    const res: Array<{ children: ReactNode; key: string }> = [];

    //no need for this
    // if (registrationMode === RegistrationMode.Default) {
    //   res.push({
    //     key: 'smsLogin',
    //     children: (
    //       <div className={'register-page__container__action-container'}>
    //         <Button
    //           variant={'outlined'}
    //           onClick={() => navigate(getConfig().smsLoginUrl)}
    //         >
    //           {translation.loginRegister.register.box.loginWithSMS}
    //           <ChatBubbleIcon />
    //         </Button>
    //       </div>
    //     ),
    //   });
    // }

    if (getConfig().AllowSocialProviders) {
      res.push({
        key: 'social',
        children: (
          <div className={'register-page__container__action-container'}>
            <Button variant={'outlined'}>
              {translation.loginRegister.register.box.oauthGoogle}
              <GoogleIcon />
            </Button>
            <Button variant={'outlined'}>
              {translation.loginRegister.register.box.oauthFacebook}
              <FacebookIcon />
            </Button>
          </div>
        ),
      });
    }

    return res;
  }, [translation]);

  const registrationCompleteOrFailed = () => {
    const header =
      view === RegistrationPhase.RegistrationComplete
        ? translation.loginRegister.register.registrationComplete.header
        : translation.loginRegister.register.registrationFailed.header;
    const desc =
      view === RegistrationPhase.RegistrationComplete
        ? translation.loginRegister.register.registrationComplete.desc
        : translation.loginRegister.register.registrationFailed.desc;
    const icon =
      view === RegistrationPhase.RegistrationComplete ? (
        <CheckIcon className={'icon icon--success'} />
      ) : (
        <ErrorIcon className={'icon icon--error'} />
      );
    const button =
      view === RegistrationPhase.RegistrationComplete
        ? translation.loginRegister.register.registrationComplete.goToLogin
        : translation.loginRegister.register.registrationFailed.tryAgain;
    return (
      <>
        <div className={'register-page__container__icon-result'}>
          {icon}
          <span dangerouslySetInnerHTML={{ __html: header }} />
        </div>
        <p className={'register-page__container__result-desc'}>{desc}</p>

        <Button
          variant={'outlined'}
          onClick={() => {
            if (view === RegistrationPhase.RegistrationComplete) {
              navigate(AppRoutes.homepage, { replace: true });
            } else {
              setView(RegistrationPhase.Form);
              navigate(AppRoutes.register, { replace: true });
            }
          }}
        >
          {button}
        </Button>
      </>
    );
  };

  return (
    <div className="register-page">
      <div className="register-page__wrapper">
        <div className="register-page__container">
          <div className="register-page__container__box">
            {view === RegistrationPhase.Form && (
              <>
                <h3>{translation.loginRegister.register.box.header}</h3>

                <div className={'register-page__container__box__inputs'}>
                  {((registrationMode === RegistrationMode.Yolo && getConfig().showNameFieldInYoloRegistration) ||
                    registrationMode !== RegistrationMode.Yolo) && (
                    <Input
                      label={translation.loginRegister.register.box.name}
                      name={'name'}
                      type={'text'}
                      value={name}
                      onChange={e => setName(e.target.value)}
                      disabled={isLoading}
                    />
                  )}

                  <Input
                    label={
                      <>
                        {translation.loginRegister.register.box.email}
                        <Tooltip
                          text={translation.loginRegister.register.box.emailTooltip}
                          icon={<InfoIcon className={'icon icon--tooltip'} />}
                          includeIconInTooltip={true}
                        />
                      </>
                    }
                    name={'email'}
                    type={'email'}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    disabled={isLoading}
                    errorList={emailError ? [translation.loginRegister.register.box.errorInvalidEmail] : []}
                    warningList={
                      emailAlreadyUsed
                        ? [
                            <span
                              dangerouslySetInnerHTML={{
                                __html: translation.loginRegister.register.box.errorAccountAlreadyExists(
                                  getConfig().loginUrl,
                                  AppRoutes.forgottenPassword.withEmail(encodeURIComponent(email)),
                                ),
                              }}
                            />,
                          ]
                        : []
                    }
                  />
                  {(registrationMode === RegistrationMode.Default ||
                    registrationMode === RegistrationMode.Password ||
                    registrationMode === RegistrationMode.Yolo) && (
                    <>
                      <Input
                        label={translation.loginRegister.register.box.password}
                        name={'password'}
                        type={'password'}
                        value={password}
                        maxLength={InputMaxPasswordLength}
                        onChange={e => setPassword(e.target.value)}
                        disabled={isLoading}
                        hasError={hasPasswordError || passwordsAreNotMatching}
                      />
                      <Input
                        label={translation.loginRegister.register.box.passwordAgain}
                        name={'passwordAgain'}
                        type={'password'}
                        value={passwordAgain}
                        maxLength={InputMaxPasswordLength}
                        hasError={hasPasswordError || passwordsAreNotMatching}
                        errorList={passwordsAreNotMatching ? [translation.passwordSet.error.doesntMatch] : []}
                        onChange={e => setPasswordAgain(e.target.value)}
                        disabled={isLoading}
                      />
                    </>
                  )}
                </div>

                {/*registerError && <div className="register-page__container__error">{registerError}</div> */}

                {(registrationMode === RegistrationMode.Default ||
                  registrationMode === RegistrationMode.Password ||
                  registrationMode === RegistrationMode.Yolo) &&
                  getPasswordHints(password, passwordAgain)}

                {needsCaptcha && (
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={recaptchaSiteKey}
                    hl={recaptchaLang}
                    onChange={handleRecaptcha}
                    className={'register-page__container__box__recaptcha'}
                    asyncScriptOnLoad={recaptchaForceRender}
                  />
                )}

                <Button
                  variant={'contained'}
                  disabled={!registerButtonEnabled || isLoading}
                  onClick={async () => {
                    await register(captchaToken, captchaWidgetReset);
                  }}
                >
                  {translation.loginRegister.register.box.register}
                </Button>

                <Button
                  variant={'text-undecorated'}
                  onClick={() => navigate(-1)}
                  disabled={isLoading}
                >
                  {translation.loginRegister.register.box.back}
                </Button>

                {orSection.length > 0 && (
                  <>
                    <div className="register-page__container__divider">
                      <span>{translation.loginRegister.register.box.oauthSectionLabel}</span>
                    </div>

                    {orSection.map(item => (
                      <Fragment key={item.key}>{item.children}</Fragment>
                    ))}
                  </>
                )}
              </>
            )}
            {view === RegistrationPhase.Result && (
              <>
                <h3>{translation.loginRegister.register.result.header}</h3>
                <div className={'register-page__container__icon-result'}>
                  <Envelope className={'icon'} />
                  <span>{translation.loginRegister.register.result.subHeader}</span>
                </div>
                <p className={'register-page__container__result-desc'}>
                  {translation.loginRegister.register.result.desc}
                  <Button
                    variant={'text'}
                    onClick={() => setView(RegistrationPhase.Form)}
                    className="button--no-left-right-padding"
                  >
                    {translation.loginRegister.register.result.resend}
                  </Button>
                </p>

                <Button
                  variant={'outlined'}
                  onClick={() => {
                    navigate(AppRoutes.homepage, { replace: true });
                  }}
                >
                  {translation.loginRegister.register.result.backToYettelAccount}
                </Button>
              </>
            )}
            {view === RegistrationPhase.EmailOTP && (
              <RegisterYoloOtpView
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                onSendAgain={async () => {
                  await yoloResend(registerAccountId);
                }}
                onCancelView={() => setView(RegistrationPhase.Form)}
                onComplete={() => setView(RegistrationPhase.Result)}
                callback={otpCode => accountResource.confirmRegistrationOTP(registerAccountId, otpCode)}
                header={translation.loginRegister.otp.header}
                title={translation.loginRegister.otp.title}
              />
            )}
            {(view === RegistrationPhase.RegistrationComplete || view === RegistrationPhase.RegistrationFailed) &&
              registrationCompleteOrFailed()}
          </div>
        </div>

        <div className="register-page__hero">
          <div className="register-page__hero__content">
            <h1>{translation.loginRegister.register.hero.header}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: translation.loginRegister.register.hero.desc(getConfig().videoChatUrl),
              }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};
