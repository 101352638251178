import { Button, Input } from '@ytl/common-web';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../contexts/currentUserSlicer';
import { useLanguage } from '../contexts/languageContext';
import { RegisterYoloOtpView } from '../modules/register/RegisterYoloOtpView';
import { getConfig } from '../shared/data/config';
import { accountResource } from '../shared/resource/account.resource';
import { isValidEmail } from '../shared/utils/emailValidator';

enum EmailChangePageState {
  NA,
  Error,
  OTP,
}

export const EmailChangePage = () => {
  const { translation } = useLanguage();
  const [email, setEmail] = useState('');
  const [emailAgain, setEmailAgain] = useState('');
  const [validationErrorList, setValidationErrorList] = useState<Array<string>>([]);
  const [state, setState] = useState<EmailChangePageState>(EmailChangePageState.NA);
  const [isLoading, setLoading] = useState(false);
  const [changeEmailError, setChangeEmailError] = useState(false);

  const currentUser = useSelector(selectCurrentUser);

  const continueClicked = useCallback(async () => {
    if (!isValidEmail(email)) {
      setValidationErrorList([translation.emailChange.error.invalidEmail]);
      return;
    }
    if (email !== emailAgain) {
      setValidationErrorList([translation.emailChange.error.emailMismatch]);
      return;
    }

    setChangeEmailError(false);
    setValidationErrorList([]);
    try {
      setLoading(true);
      await accountResource.changeEmailYolo(email, currentUser!.accountId);
      setState(EmailChangePageState.OTP);
    } catch (err) {
      setChangeEmailError(true);
    } finally {
      setLoading(false);
    }
  }, [email, emailAgain, translation, currentUser]);

  return (
    <div className="email-change-page">
      {state === EmailChangePageState.NA && (
        <>
          <h3>{translation.emailChange.header}</h3>
          <p>{translation.emailChange.title}</p>
          <Input
            label={translation.emailChange.email}
            name={'email'}
            value={email}
            required={true}
            onChange={e => setEmail(e.target.value)}
            errorList={validationErrorList}
            disabled={isLoading}
          />
          <Input
            label={translation.emailChange.emailAgain}
            name={'emailAgain'}
            value={emailAgain}
            required={true}
            onChange={e => setEmailAgain(e.target.value)}
            disabled={isLoading}
            errorList={changeEmailError ? [translation.emailChange.error.general] : []}
          />

          <Button
            variant={'contained'}
            disabled={!email || !emailAgain || isLoading}
            onClick={continueClicked}
          >
            {translation.emailChange.continue}
          </Button>
        </>
      )}
      {state === EmailChangePageState.OTP && (
        <RegisterYoloOtpView
          isLoading={isLoading}
          setIsLoading={setLoading}
          onComplete={() => (window.location.href = getConfig().closeWebViewUrl)}
          onSendAgain={continueClicked}
          callback={otp => accountResource.changeEmailYoloConfirm(currentUser!.accountId, otp)}
          header={translation.emailChange.otpHeader}
          title={''}
        />
      )}
    </div>
  );
};
